<template>
    <div id="app">
        <notAllowed />
    </div>
</template>

<script>
import notAllowed from './notAllowed.vue'
export default{
    name: 'App',
    components:{
        notAllowed
  }
}
</script>